import React from 'react'
import Header2 from '../../components/shared/Header2'
import Footer2 from '../../components/shared/Footer2'
import foldleft from '../../assets/about/fold-left.png';
import foldright from '../../assets/about/fold-right.png';
import top3 from '../../assets/new-image/new-bg.png';
import girl2 from '../../assets/about/girl2.png';
import pageLeft from '../../assets/about/page-left.png';
import moon from '../../assets/about/moon.png';
import saturn from '../../assets/about/saturn.png';
import boy from '../../assets/about/boy.png';
import tab2 from '../../assets/about/tab-2.png';
import rocate from '../../assets/about/rocate.png';



function ProductNew() {


    return (
        <div>
            <Header2 />

            {/* about banner start*/}
            <section className="bg-banner-home relative " >
                <div className='max-w-7xl lg:px-0 px-4 mx-auto'>
                    <div className="flex lg:flex-row flex-col items-center justify-between gap-8 lg:pt-[133px] lg:pb-20 pt-10">
                        <div className='lg:max-w-[900px] mx-auto'>
                            <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                                Third Bracket’s Platform as a Service: Redefining Onboarding for New Talent
                            </h3>
                            <p className='font-normal lg:text-lg text-center text-base  text-[#40566D]  mt-10 lg:mt-14 mx-auto ff-inter'>
                                At Third Bracket, we’ve reimagined the way organizations identify, onboard, and integrate new talent. Our Platform as a Service (PaaS) is the ultimate tool to help businesses streamline talent acquisition while fostering a culture of innovation and growth.
                            </p>

                        </div>
                    </div>
                </div>

                <div className='top-image-1 lg:block hidden w-full absolute lg:-top-[20%] left-0 z-[-1]'>
                    <img src={top3} className='w-full h-auto object-contain' alt="" />
                </div>
            </section>
            {/* about banner end */}

            {/* Why Use Third Bracket start */}
            <div className='bg-[#FBFBFB] pt-5 relative lg:px-0 px-4 '>
                <div>
                    <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center'>Key Features of Our Platform</h3>
                </div>
                <div className='flex flex-col-reverse lg:flex-row items-center justify-between mx-auto max-w-[1150px] gap-10 lg:gap-20'>
                    <div>
                        <div className='lg:mt-20'>
                            <h3 className='text-[#191919] text-lg lg:text-xl font-bold ff-pop text-left'>1. Publish Job Roles and Descriptions with Precision</h3>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] my-5 mx-auto ff-inter'>
                                Easily craft and publish comprehensive job roles and descriptions tailored to your organization’s unique needs. Our platform enables you to:.
                            </p>
                            <ul className='list-disc mb-0'>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                    Define clear role expectations and responsibilities.
                                </li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                    Align job descriptions with business goals
                                </li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>Optimize visibility to attract the best-fit talent</li>
                            </ul>
                        </div>
                        <div className='mt-10 lg:mt-20'>
                            <h3 className='text-[#191919] text-lg lg:text-xl font-bold ff-pop text-left'>2. Build Tailored Competencies and Capabilities</h3>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] my-5 mx-auto ff-inter'>
                                Identify and define the specific skills and capabilities each role demands. With Third Bracket, you can:
                            </p>
                            <ul className='list-disc mb-0'>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                    Create customized competency frameworks.
                                </li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                    Define technical, functional, and behavioral skills.
                                </li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>Align role capabilities with long-term organizational objectives.</li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex items-center justify-center'>
                        <img src={girl2} alt="w-full h-auto object-contain" />
                    </div>
                </div>
                <div className='flex flex-col lg:flex-col my-10 lg:my-20 items-start justify-between mx-auto max-w-[1150px]  gap-10 lg:gap-20'>
                    <div className='flex-1'>
                        <h3 className='text-[#191919] text-lg lg:text-xl font-bold ff-pop text-left'>3. Skill Fitment Assessment</h3>
                        <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] my-5 mx-auto ff-inter'>
                            Our platform empowers you to evaluate the expertise of potential hires through data-driven skill assessments. With our solution, you can:
                        </p>
                        <ul className='list-disc mb-0'>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                Develop targeted skill evaluation modules.
                            </li>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                Identify gaps and areas for development.
                            </li>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>Ensure the candidate’s technical proficiency aligns with your needs.</li>
                        </ul>
                    </div>
                    <div className='flex-1'>
                        <h3 className='text-[#191919] text-lg lg:text-xl font-bold ff-pop text-left'>4. Culture Fitment Assessment</h3>
                        <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] my-5 mx-auto ff-inter'>
                            Hiring for culture fit is as important as hiring for skills. With our culture fitment assessment tools, you can:
                        </p>
                        <ul className='list-disc mb-0'>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                Evaluate alignment with company values and mission.
                            </li>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>
                                Understand candidate attitudes, behaviors, and preferences.
                            </li>
                            <li className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-1 ff-inter'>Build teams that foster collaboration and innovation.</li>
                        </ul>
                    </div>
                </div>

            </div>

            <div className='bg-[#FFC727]  relative overflow-hidden'>
                <div className='lg:py-28 py-10 px-4 lg:px-0 relative z-10' >
                    <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                        Third Bracket’s Platform as a Service: <br className='lg:block hidden' /> Upskilling existing workforce
                    </h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#40566D] lg:max-w-[600px] lg:mt-16 mt-10 mx-auto ff-inter'>
                        At Third Bracket, we believe the future of your organization depends on the growth of your people. That’s why our cutting-edge Platform as a Service is designed to empower you to upskill your existing employees by transforming potential into performance.
                    </p>

                    <h3 className='text-[#191919] text-lg mt-10 lg:mt-24 lg:text-[26px] font-bold ff-pop text-center'>Comprehensive Competency & Capability Assessment</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-7 mx-auto ff-inter'>
                        Unlock the full potential of your workforce with precise insights into their current competencies and capabilities. Our platform uses advanced tools and data-driven methodologies to evaluate each employee&#39;s strengths, skill gaps, and growth opportunities, giving you a clear roadmap for talent development.
                    </p>
                    <h3 className='text-[#191919] text-lg mt-10  lg:mt-24 lg:text-[26px] font-bold ff-pop text-center'>Skill Fitment Analysis</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-7 mx-auto ff-inter'>
                        Are your employees in the right roles? Our platform enables you to assess skill fitment, ensuring your talent is aligned with their strengths and the organization’s goals. This targeted approach optimizes individual contributions and drives organizational success motivated, and prepared to drive organizational success.
                    </p>

                    <h3 className='text-[#191919] text-lg mt-10  lg:mt-24 lg:text-[26px] font-bold ff-pop text-center'>Culture Fitment Evaluation</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-7 mx-auto ff-inter'>
                        Thriving workplaces are built on cultural synergy. With our culture fitment assessment, identify how well each employee aligns with your company values and work environment. Foster a cohesive team where everyone feels connected and valued.
                    </p>

                    <h3 className='text-[#191919] text-lg mt-10  lg:mt-24 lg:text-[26px] font-bold ff-pop text-center'>Tailored Learning Framework for Upskilling</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-7 mx-auto ff-inter'>
                        Invest in your people with a personalized learning framework designed to bridge skill gaps, enhance expertise, and meet future demands. From technical training to leadership development, our platform creates a dynamic, scalable upskilling roadmap that evolves with your workforce.
                    </p>
                    <h3 className='text-[#191919] text-lg mt-10  lg:mt-24 lg:text-[26px] font-bold ff-pop text-center'>Why Choose Third Bracket?</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-7 mx-auto ff-inter'>
                        <b className='ff-inter'> Data-Driven Insights:</b> Make informed decisions with real-time analytics and actionable insights. <br />
                        <b className='ff-inter'> Personalized Growth Paths:</b> Address unique employee needs with tailored learning plans. <br />
                        <b className='ff-inter'>Future-Ready Workforce:</b> Equip your team with skills to adapt and excel in a rapidly changing world. <br />
                    </p>

                </div>
                <div className='w-20 lg:w-[300px]  absolute bottom-0 right-0'>
                    <img src={boy} width={326} alt="fold-right" className='w-full h-auto object-contain' />
                </ div>
                <div className='w-20 lg:w-[200px]  absolute top-1/2 -translate-y-1/2 right-0'>
                    <img src={moon} width={200} alt="fold-right" className='w-full h-auto object-contain' />
                </ div>
                <div className='w-20 lg:w-[150px]  absolute left-10 -translate-y-1/2 top-2/3'>
                    <img src={saturn} width={150} alt="fold-right" className='w-full h-auto object-contain' />
                </ div>
                <div className='w-20 lg:w-[200px]  absolute top-0 left-0'>
                    <img src={tab2} width={200} alt="fold-right" className='w-full h-auto object-contain' />
                </ div>
                <div className='w-10 lg:w-[326px] 2xl:w-[400px] lg:block hidden absolute bottom-0 left-0'>
                    <img src={foldleft} width={500} alt="fold-right" className='w-full h-auto object-contain' />
                </div>
            </div>

            <div className='relative overflow-hidden pt-10 lg:pt-28 lg:px-0 px-4'>
                <div className='w- lg:block hidden  absolute lg:bottom-0 right-0 z-[1]'>
                    <img src={rocate} className='w-full h-auto' alt="" />
                </div>
                <div className='relative z-10'>
                    <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                        AI Plus
                    </h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#40566D] lg:max-w-[800px] mt-10 mx-auto ff-inter'>
                        <b className='ff-inter'> Third Bracket: Revolutionizing Talent Insights with AI-Driven Competency Mapping</b> <br />
                        Welcome to Third Bracket, where innovation meets precision. Our Platform as a Service (PaaS) harnesses the power of AI to transform the way organizations map competencies, benchmark capabilities, and assess employee potential.
                    </p>

                    <div className='lg:ml-52 max-w-[640px] mt-10 pb-10 lg:mt-36 lg:pb-48'>
                        <div className=''>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-left'>Empower Talent Strategy with AI-Driven Insights</h3>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262]  mt-5 ff-inter'>
                                <b className='ff-inter'>Third Bracket enables organizations to:</b> <br />
                                Generate Comprehensive Competency Mapping:
                                Effortlessly identify the functional and behavioral skills of your current workforce. Our platform uses advanced AI algorithms to analyze employee
                                strengths, uncover development areas, and align competencies with organizational goals
                            </p>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262]  mt-5 ff-inter'>
                                <b className='ff-inter'>Establish Capability Benchmarks:</b>
                                Set clear, data-driven benchmarks for success. Compare individual capabilities against industry standards or organizational expectations to drive
                                informed talent decisions.</p>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262]  mt-5 ff-inter'><b className='ff-inter'>Create Custom Assessments for Role Fitment:</b>
                                Design and deploy tailored assessments directly from the platform to evaluate:</p>
                            <ul className=' mt-5 list-disc' >
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] mb-1 ff-inter'>Role Fitment: Ensure employees are matched with positions that align with their skills and aspirations. </li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] mb-1 ff-inter'> Functional Skills: Assess job-specific expertise with precision.</li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] mb-1 ff-inter'> Behavioral Skills: Evaluate traits like adaptability, teamwork, and leadership potential.</li>
                                <li className='font-normal lg:text-lg text-left text-base  text-[#626262] mb-1  ff-inter'> Culture Fitment: Determine alignment with organizational values and work culture.</li>
                            </ul>
                        </div>
                        <div className='mt-10 lg:mt-24'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-left'>Effortless Integration with Existing Systems</h3>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mt-5 ff-inter'>
                                We understand that the strength of a system lies in its ability to work together. That’s why our platforms are built with integration in mind. Whether you’re enhancing your existing ecosystem or creating a new one, our tools connect seamlessly with your current platforms, ensuring a smooth and unified
                                experience.
                            </p>
                        </div>
                        <div className='mt-10 lg:mt-24'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-left mb-5'>Why Third Bracket?</h3>
                            <ul className='ml-0 m-0 list-disc'>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>AI-Powered Precision:</b> Our platform leverages cutting-edge AI to deliver insights that are accurate, actionable, and tailored to your needs.
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Seamless Integration:</b> Integrates effortlessly into your existing HR ecosystem
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Customisable Assessments:</b> Build assessments that reflect the unique demands of your organisation and roles
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Data-Driven Decisions:</b> Empower leadership with real-time analytics and actionable reports to guide talent strategies.
                                    </p>
                                </li>

                            </ul>
                        </div>
                        <div className='mt-10 lg:mt-24'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-left'>Discover the Future of Talent Management</h3>
                            <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mt-5 ff-inter'>
                                At Third Bracket, we believe in unlocking the full potential of your workforce. With our PaaS solution, you’ll not only enhance employee performance but also drive organizational growth by aligning talent strategies with business objectives.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='top-image-1 lg:block hidden w-full absolute top-0 left-0 z-[-1]'>
                    <img src={top3} className='w-full h-auto' alt="" />
                </div>
            </div>
            {/* Why Use Third Bracket end */}

            {/* About Us start */}

            <Footer2 />
        </div>
    )
}

export default ProductNew