import Header2 from '../../components/shared/Header2'
import orgnisa1 from '../../assets/new-image/image1.png';
import orgnisa2 from '../../assets/new-image/image2.png';
import orgnisa3 from '../../assets/new-image/image3.png';
import orgnisa4 from '../../assets/new-image/image4.png';
import orgnisa5 from '../../assets/new-image/image5.png';
import orgnisa6 from '../../assets/new-image/image6.png';
import HomeBanner from '../Landing/HomeBanner';
import Footer2 from '../../components/shared/Footer2';
import FAQ from './FAQ';
import top2 from '../../assets/new-image/new-bg.png';
import top4 from '../../assets/new-image/bg-3.png';
import top3 from '../../assets/new-image/new-bg-2.png';
import img1 from '../../assets/new-image/img1.png';
import middlesperate from '../../assets/new-image/middle-sperate.png';
import character from '../../assets/new-image/character-1.png';
import Sign from '../../assets/new-image/Sign.png';
import Screen from '../../assets/new-image/Screen.png';
import baggg from '../../assets/new-image/baggg.png';
import yellowbol from '../../assets/new-image/yellowbol.png';
import character2 from '../../assets/new-image/Character.png';
import yelobg from '../../assets/new-image/yelobg.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import { useEffect, useRef, useState } from 'react';
import IntegrationAccordion from './FAQ';
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";



const Home: React.FC = () => {
    const images = [orgnisa1, orgnisa2, orgnisa3, orgnisa4, orgnisa5, orgnisa6];
    const [duplicatedImages, setDuplicatedImages] = useState<string[]>([]);
    const marqueeRef = useRef<HTMLDivElement>(null);
    const { ref, inView } = useInView({ triggerOnce: false, threshold: 0.5 });

    const counterData = [
        { end: 100, label: "Learning <br /> solutions" },
        { end: 700, label: "Top Hiring <br /> Candidates" },
        { end: 300, label: "Skill Profiling  <br /> Individual Users" },
        { end: 10, label: "Skill profiling for <br /> Mid-Level Leaders" },
    ];

    useEffect(() => {
        // Create a seamless loop by duplicating images
        setDuplicatedImages([...images, ...images, ...images, ...images, ...images, ...images, ...images, ...images]);
    }, []);  // Remove 'images' dependency
    return (
        <>
            <Header2 />
            {/*  banner section html */}
            <HomeBanner />
            {/* /banner section html */}
            <div className='bg-white  pb-10 pt-3 h-64 lg:h-full  flex flex-col justify-center overflow-hidden'>
                <div className='lg:mb-[52px] mb-9'>
                    <h3 className='text-[#191919] lg:text-center ff-pop font-semibold text-[28px] lg:text-[46px] leading-tight  my-0'>Associated Trusted Enterprises</h3>
                </div>
                <div
                    ref={marqueeRef}
                    className="flex will-change-transform items-center justify-center gap-32"
                    style={{
                        animation: 'marquee 80s linear infinite',
                        width: 'fit-content'
                    }}
                >
                    {duplicatedImages.map((imgSrc, index) => (
                        <div
                            key={index}
                            className="flex-shrink-0 flex items-center justify-center w-32 mx-2"
                        >
                            <img
                                src={imgSrc}
                                alt={`organization ${index + 1}`}
                                className="max-w-full w-full h-full object-contain"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/*  Organisations section html */}
            <div className='py-[61px] bg-[#FBFBFB] lg:block hidden'></div>
            <div className='bg-[#F6F6F6] relative' >
                <div className='w-[105px] h-[105px] flex flex-row items-center justify-center rounded-full mx-auto circle-box !bg-[#F6F6F6] absolute top-0 left-1/2'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 5V19M12 19L19 12M12 19L5 12" stroke="#061927" stroke-linejoin="round" />
                    </svg>
                </div>
                <div className="mx-auto max-w-7xl lg:px-0 px-4  pt-16 lg:py-0">
                    <div className="lg:py-[96px] pb-16 lg:gap-10 gap-14">
                        <h3 className='text-[#191919] font-semibold capitalize ff-pop md:text-3xl text-[28px] lg:text-[46px] !leading-[1.3] text-center my-0'>Skill-development thusfar</h3>
                        <div
                            ref={ref}
                            className="flex lg:flex-row flex-col items-center justify-between lg:max-w-[75%] mx-auto mt-8 gap-16 lg:gap-0 lg:mt-16"
                        >
                            {counterData.map((item, index) => (
                                <div
                                    key={index}
                                    className="flex-1 flex flex-col justify-center items-center lg:gap-3 gap-4"
                                >
                                    <h3 className="xl:text-[56px] lg:text-5xl text-4xl leading-tight text-center font-semibold font-poppins text-[#061927] counting-box">
                                        {inView ? <CountUp start={0} end={item.end} duration={2.5} separator="," /> : 0}K
                                    </h3>
                                    <p
                                        className="lg:text-lg text-base ff-pop leading-tight font-normal tracking-tight text-[#626262] ff-inter text-center"
                                        dangerouslySetInnerHTML={{ __html: item.label }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

            <div className='employee-skill-wrapper lg:pt-28 lg:pb-32 py-16 px-5'>
                <div className='relative'>
                    <div className='top-image-1 lg:block hidden w-full absolute lg:-top-[20%] xl:-top-[10%] left-0 z-[-1]'>
                        <img src={top2} className='w-full h-auto' alt="" />
                    </div>
                    <div className='max-w-3xl mx-auto flex flex-col gap-[18px] justify-center items-center '>
                        <h3 className='text-[#191919] font-semibold capitalize ff-pop md:text-3xl text-[28px] lg:text-[46px] !leading-[1.3] text-center my-0'>AI+ Powered Employee Skill Enhancement</h3>
                        <p className='font-normal text-[#40566D] lg:text-lg text-base tracking-[-3%] text-center ff-inter'>Third Bracket leverages advanced Artificial Intelligence (AI) to revolutionise employee skill development by identifying capability and competency gaps with
                            precision. Here’s how</p>
                    </div>
                    <div className='flex flex-col items-center justify-center mt-16 gap-10'>
                        <div className='flex-1 lg:hidden '>
                            <img src={img1} className='w-full h-auto' width={500} height={400} alt="value" />
                        </div>
                        <div className='flex flex-col items-start relative justify-center  mx-auto lg:py-10 lg:px-16 p-5 border-[#455A64] rounded-lg gap-1 border lg:max-w-[600px]'>
                            <div className="lg:hidden mb-5">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1636_21166" x="0" y="0" width="52" height="52">
                                        <rect width="52" height="52" fill="#FFC727" />
                                    </mask>
                                    <g mask="url(#mask0_1636_21166)">
                                        <path d="M26 48.7503C23.4 46.2948 20.4208 44.417 17.0625 43.117C13.7042 41.817 10.1833 41.167 6.5 41.167V17.3337C10.1472 17.3337 13.65 17.9927 17.0083 19.3107C20.3667 20.6288 23.3639 22.5337 26 25.0253C28.6361 22.5337 31.6333 20.6288 34.9917 19.3107C38.35 17.9927 41.8528 17.3337 45.5 17.3337V41.167C41.7806 41.167 38.2507 41.817 34.9104 43.117C31.5701 44.417 28.6 46.2948 26 48.7503ZM26 19.5003C23.6167 19.5003 21.5764 18.6517 19.8792 16.9545C18.1819 15.2573 17.3333 13.217 17.3333 10.8337C17.3333 8.45033 18.1819 6.41005 19.8792 4.71283C21.5764 3.0156 23.6167 2.16699 26 2.16699C28.3833 2.16699 30.4236 3.0156 32.1208 4.71283C33.8181 6.41005 34.6667 8.45033 34.6667 10.8337C34.6667 13.217 33.8181 15.2573 32.1208 16.9545C30.4236 18.6517 28.3833 19.5003 26 19.5003Z" fill="#FFC727" />
                                    </g>
                                </svg>

                            </div>
                            <h3 className='ff-inter  text-lg leading-[1.5] text-[#191919] font-bold'>Personalized Learning Paths: </h3>
                            <p className='ff-inter font-normal text-lg leading-[1.5] text-[#626262]'>Personalized Learning Paths: Based on the identified gaps, AI recommends tailored upskilling programs and training resources, ensuring
                                employees receive the right development opportunities at the right time.</p>
                            <div className="absolute bottom-5 right-4 hidden lg:block " >
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1636_21166" x="0" y="0" width="52" height="52">
                                        <rect width="52" height="52" fill="#FFC727" />
                                    </mask>
                                    <g mask="url(#mask0_1636_21166)">
                                        <path d="M26 48.7503C23.4 46.2948 20.4208 44.417 17.0625 43.117C13.7042 41.817 10.1833 41.167 6.5 41.167V17.3337C10.1472 17.3337 13.65 17.9927 17.0083 19.3107C20.3667 20.6288 23.3639 22.5337 26 25.0253C28.6361 22.5337 31.6333 20.6288 34.9917 19.3107C38.35 17.9927 41.8528 17.3337 45.5 17.3337V41.167C41.7806 41.167 38.2507 41.817 34.9104 43.117C31.5701 44.417 28.6 46.2948 26 48.7503ZM26 19.5003C23.6167 19.5003 21.5764 18.6517 19.8792 16.9545C18.1819 15.2573 17.3333 13.217 17.3333 10.8337C17.3333 8.45033 18.1819 6.41005 19.8792 4.71283C21.5764 3.0156 23.6167 2.16699 26 2.16699C28.3833 2.16699 30.4236 3.0156 32.1208 4.71283C33.8181 6.41005 34.6667 8.45033 34.6667 10.8337C34.6667 13.217 33.8181 15.2573 32.1208 16.9545C30.4236 18.6517 28.3833 19.5003 26 19.5003Z" fill="#FFC727" />
                                    </g>
                                </svg>

                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col lg:gap-0 gap-10 items-stretch justify-center'>
                            <div className='flex flex-col items-start relative justify-start  mx-auto lg:py-10 lg:px-16 p-5 bg-[#F0F0F0] rounded-lg gap-1 lg:max-w-[400px]'>
                                <div className="lg:hidden mb-5">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21146" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#455A64" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21146)">
                                            <path d="M8.66927 45.5007C7.4776 45.5007 6.45747 45.0763 5.60885 44.2277C4.76024 43.3791 4.33594 42.359 4.33594 41.1673V17.334C4.33594 16.1423 4.76024 15.1222 5.60885 14.2736C6.45747 13.425 7.4776 13.0007 8.66927 13.0007H17.3359V8.66732C17.3359 7.47565 17.7602 6.45551 18.6089 5.6069C19.4575 4.75829 20.4776 4.33398 21.6693 4.33398H30.3359C31.5276 4.33398 32.5477 4.75829 33.3964 5.6069C34.245 6.45551 34.6693 7.47565 34.6693 8.66732V13.0007H43.3359C44.5276 13.0007 45.5477 13.425 46.3964 14.2736C47.245 15.1222 47.6693 16.1423 47.6693 17.334V41.1673C47.6693 42.359 47.245 43.3791 46.3964 44.2277C45.5477 45.0763 44.5276 45.5007 43.3359 45.5007H8.66927ZM21.6693 13.0007H30.3359V8.66732H21.6693V13.0007Z" fill="#455A64" />
                                        </g>
                                    </svg>
                                </div>
                                <h3 className='ff-inter  text-lg leading-[1.5] text-[#191919] font-bold'>Skill Mapping </h3>
                                <p className='ff-inter font-normal text-lg leading-[1.5] text-[#626262]'>Our AI-driven algorithms analyze employee profiles, performance data, and role requirements to create detailed skill maps,highlighting strengths and areas for improvement.</p>
                                <div className="absolute bottom-5 right-4 lg:block hidden">

                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21146" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#455A64" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21146)">
                                            <path d="M8.66927 45.5007C7.4776 45.5007 6.45747 45.0763 5.60885 44.2277C4.76024 43.3791 4.33594 42.359 4.33594 41.1673V17.334C4.33594 16.1423 4.76024 15.1222 5.60885 14.2736C6.45747 13.425 7.4776 13.0007 8.66927 13.0007H17.3359V8.66732C17.3359 7.47565 17.7602 6.45551 18.6089 5.6069C19.4575 4.75829 20.4776 4.33398 21.6693 4.33398H30.3359C31.5276 4.33398 32.5477 4.75829 33.3964 5.6069C34.245 6.45551 34.6693 7.47565 34.6693 8.66732V13.0007H43.3359C44.5276 13.0007 45.5477 13.425 46.3964 14.2736C47.245 15.1222 47.6693 16.1423 47.6693 17.334V41.1673C47.6693 42.359 47.245 43.3791 46.3964 44.2277C45.5477 45.0763 44.5276 45.5007 43.3359 45.5007H8.66927ZM21.6693 13.0007H30.3359V8.66732H21.6693V13.0007Z" fill="#455A64" />
                                        </g>
                                    </svg>


                                </div>
                            </div>
                            <div className='flex-1 lg:block hidden'>
                                <img src={img1} className='w-full h-auto' width={500} height={400} alt="value" />
                            </div>
                            <div className='flex lg:hidden flex-col items-start relative justify-center  mx-auto lg:py-10 lg:px-16 p-5  border-[#455A64] rounded-lg gap-1 border lg:max-w-[600px]'>
                                <div className="lg:hidden mb-5">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21184" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#FFC727" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21184)">
                                            <path d="M10.8333 45.5C9.64167 45.5 8.62153 45.0757 7.77292 44.2271C6.92431 43.3785 6.5 42.3583 6.5 41.1667V10.8333C6.5 9.64167 6.92431 8.62153 7.77292 7.77292C8.62153 6.92431 9.64167 6.5 10.8333 6.5H41.1667C42.3583 6.5 43.3785 6.92431 44.2271 7.77292C45.0757 8.62153 45.5 9.64167 45.5 10.8333V27.5167L37.5917 35.425L32.9875 30.8208L23.7792 39.975L29.3042 45.5H10.8333ZM23.8333 28.1667H36.8333V23.8333H23.8333V28.1667ZM23.8333 19.5H36.8333V15.1667H23.8333V19.5ZM37.5917 47.6667L29.9 39.975L32.9875 36.9417L37.5917 41.5458L46.8 32.3375L49.8333 35.425L37.5917 47.6667ZM17.3333 28.1667C17.9472 28.1667 18.4618 27.959 18.8771 27.5438C19.2924 27.1285 19.5 26.6139 19.5 26C19.5 25.3861 19.2924 24.8715 18.8771 24.4563C18.4618 24.041 17.9472 23.8333 17.3333 23.8333C16.7194 23.8333 16.2049 24.041 15.7896 24.4563C15.3743 24.8715 15.1667 25.3861 15.1667 26C15.1667 26.6139 15.3743 27.1285 15.7896 27.5438C16.2049 27.959 16.7194 28.1667 17.3333 28.1667ZM17.3333 19.5C17.9472 19.5 18.4618 19.2924 18.8771 18.8771C19.2924 18.4618 19.5 17.9472 19.5 17.3333C19.5 16.7194 19.2924 16.2049 18.8771 15.7896C18.4618 15.3743 17.9472 15.1667 17.3333 15.1667C16.7194 15.1667 16.2049 15.3743 15.7896 15.7896C15.3743 16.2049 15.1667 16.7194 15.1667 17.3333C15.1667 17.9472 15.3743 18.4618 15.7896 18.8771C16.2049 19.2924 16.7194 19.5 17.3333 19.5Z" fill="#FFC727" />
                                        </g>
                                    </svg>
                                </div>
                                <h3 className='ff-inter  text-lg leading-[1.5] text-[#191919] font-bold'>Predictive Insights:</h3>
                                <p className='ff-inter font-normal text-lg leading-[1.5] text-[#626262]'>By analyzing trends and patterns, AI provides insights into future skills employees will need to stay competitive and aligned with
                                    organizational goals.</p>
                                <div className="absolute bottom-5 right-4 lg:block hidden">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21184" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#FFC727" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21184)">
                                            <path d="M10.8333 45.5C9.64167 45.5 8.62153 45.0757 7.77292 44.2271C6.92431 43.3785 6.5 42.3583 6.5 41.1667V10.8333C6.5 9.64167 6.92431 8.62153 7.77292 7.77292C8.62153 6.92431 9.64167 6.5 10.8333 6.5H41.1667C42.3583 6.5 43.3785 6.92431 44.2271 7.77292C45.0757 8.62153 45.5 9.64167 45.5 10.8333V27.5167L37.5917 35.425L32.9875 30.8208L23.7792 39.975L29.3042 45.5H10.8333ZM23.8333 28.1667H36.8333V23.8333H23.8333V28.1667ZM23.8333 19.5H36.8333V15.1667H23.8333V19.5ZM37.5917 47.6667L29.9 39.975L32.9875 36.9417L37.5917 41.5458L46.8 32.3375L49.8333 35.425L37.5917 47.6667ZM17.3333 28.1667C17.9472 28.1667 18.4618 27.959 18.8771 27.5438C19.2924 27.1285 19.5 26.6139 19.5 26C19.5 25.3861 19.2924 24.8715 18.8771 24.4563C18.4618 24.041 17.9472 23.8333 17.3333 23.8333C16.7194 23.8333 16.2049 24.041 15.7896 24.4563C15.3743 24.8715 15.1667 25.3861 15.1667 26C15.1667 26.6139 15.3743 27.1285 15.7896 27.5438C16.2049 27.959 16.7194 28.1667 17.3333 28.1667ZM17.3333 19.5C17.9472 19.5 18.4618 19.2924 18.8771 18.8771C19.2924 18.4618 19.5 17.9472 19.5 17.3333C19.5 16.7194 19.2924 16.2049 18.8771 15.7896C18.4618 15.3743 17.9472 15.1667 17.3333 15.1667C16.7194 15.1667 16.2049 15.3743 15.7896 15.7896C15.3743 16.2049 15.1667 16.7194 15.1667 17.3333C15.1667 17.9472 15.3743 18.4618 15.7896 18.8771C16.2049 19.2924 16.7194 19.5 17.3333 19.5Z" fill="#FFC727" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className='flex flex-col items-start relative justify-start  mx-auto lg:py-10 lg:px-16 p-5 bg-[#F0F0F0] rounded-lg gap-1 lg:max-w-[400px]'>
                                <div className="lg:hidden mb-5">
                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21178" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#455A64" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21178)">
                                            <path d="M45.5 34.6667L25.1333 18.7417L16.5208 30.6042L6.5 22.75V15.1667L15.1667 21.6667L26 6.5L36.8333 15.1667H45.5V34.6667ZM6.5 43.3333V28.1667L17.3333 36.8333L26 24.9167L45.5 40.1375V43.3333H6.5Z" fill="#455A64" />
                                        </g>
                                    </svg>
                                </div>
                                <h3 className='ff-inter  text-lg leading-[1.5] text-[#191919] font-bold'>Gap Analysis: </h3>
                                <p className='ff-inter font-normal text-lg leading-[1.5] text-[#626262]'> AI compares current employee competencies with industry benchmarks and organisational needs, pinpointing specific gaps in
                                    capabilities.</p>
                                <div className="absolute bottom-5 right-4 lg:block hidden">


                                    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1636_21178" x="0" y="0" width="52" height="52">
                                            <rect width="52" height="52" fill="#455A64" />
                                        </mask>
                                        <g mask="url(#mask0_1636_21178)">
                                            <path d="M45.5 34.6667L25.1333 18.7417L16.5208 30.6042L6.5 22.75V15.1667L15.1667 21.6667L26 6.5L36.8333 15.1667H45.5V34.6667ZM6.5 43.3333V28.1667L17.3333 36.8333L26 24.9167L45.5 40.1375V43.3333H6.5Z" fill="#455A64" />
                                        </g>
                                    </svg>


                                </div>
                            </div>
                        </div>
                        <div className='hidden lg:flex flex-col items-start relative justify-center  mx-auto lg:py-10 lg:px-16 p-5  border-[#455A64] rounded-lg gap-1 border lg:max-w-[600px]'>
                            <div className="lg:hidden mb-5">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1636_21184" x="0" y="0" width="52" height="52">
                                        <rect width="52" height="52" fill="#FFC727" />
                                    </mask>
                                    <g mask="url(#mask0_1636_21184)">
                                        <path d="M10.8333 45.5C9.64167 45.5 8.62153 45.0757 7.77292 44.2271C6.92431 43.3785 6.5 42.3583 6.5 41.1667V10.8333C6.5 9.64167 6.92431 8.62153 7.77292 7.77292C8.62153 6.92431 9.64167 6.5 10.8333 6.5H41.1667C42.3583 6.5 43.3785 6.92431 44.2271 7.77292C45.0757 8.62153 45.5 9.64167 45.5 10.8333V27.5167L37.5917 35.425L32.9875 30.8208L23.7792 39.975L29.3042 45.5H10.8333ZM23.8333 28.1667H36.8333V23.8333H23.8333V28.1667ZM23.8333 19.5H36.8333V15.1667H23.8333V19.5ZM37.5917 47.6667L29.9 39.975L32.9875 36.9417L37.5917 41.5458L46.8 32.3375L49.8333 35.425L37.5917 47.6667ZM17.3333 28.1667C17.9472 28.1667 18.4618 27.959 18.8771 27.5438C19.2924 27.1285 19.5 26.6139 19.5 26C19.5 25.3861 19.2924 24.8715 18.8771 24.4563C18.4618 24.041 17.9472 23.8333 17.3333 23.8333C16.7194 23.8333 16.2049 24.041 15.7896 24.4563C15.3743 24.8715 15.1667 25.3861 15.1667 26C15.1667 26.6139 15.3743 27.1285 15.7896 27.5438C16.2049 27.959 16.7194 28.1667 17.3333 28.1667ZM17.3333 19.5C17.9472 19.5 18.4618 19.2924 18.8771 18.8771C19.2924 18.4618 19.5 17.9472 19.5 17.3333C19.5 16.7194 19.2924 16.2049 18.8771 15.7896C18.4618 15.3743 17.9472 15.1667 17.3333 15.1667C16.7194 15.1667 16.2049 15.3743 15.7896 15.7896C15.3743 16.2049 15.1667 16.7194 15.1667 17.3333C15.1667 17.9472 15.3743 18.4618 15.7896 18.8771C16.2049 19.2924 16.7194 19.5 17.3333 19.5Z" fill="#FFC727" />
                                    </g>
                                </svg>
                            </div>
                            <h3 className='ff-inter  text-lg leading-[1.5] text-[#191919] font-bold'>Predictive Insights:</h3>
                            <p className='ff-inter font-normal text-lg leading-[1.5] text-[#626262]'>By analyzing trends and patterns, AI provides insights into future skills employees will need to stay competitive and aligned with
                                organizational goals.</p>
                            <div className="absolute bottom-5 right-4 lg:block hidden">
                                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_1636_21184" x="0" y="0" width="52" height="52">
                                        <rect width="52" height="52" fill="#FFC727" />
                                    </mask>
                                    <g mask="url(#mask0_1636_21184)">
                                        <path d="M10.8333 45.5C9.64167 45.5 8.62153 45.0757 7.77292 44.2271C6.92431 43.3785 6.5 42.3583 6.5 41.1667V10.8333C6.5 9.64167 6.92431 8.62153 7.77292 7.77292C8.62153 6.92431 9.64167 6.5 10.8333 6.5H41.1667C42.3583 6.5 43.3785 6.92431 44.2271 7.77292C45.0757 8.62153 45.5 9.64167 45.5 10.8333V27.5167L37.5917 35.425L32.9875 30.8208L23.7792 39.975L29.3042 45.5H10.8333ZM23.8333 28.1667H36.8333V23.8333H23.8333V28.1667ZM23.8333 19.5H36.8333V15.1667H23.8333V19.5ZM37.5917 47.6667L29.9 39.975L32.9875 36.9417L37.5917 41.5458L46.8 32.3375L49.8333 35.425L37.5917 47.6667ZM17.3333 28.1667C17.9472 28.1667 18.4618 27.959 18.8771 27.5438C19.2924 27.1285 19.5 26.6139 19.5 26C19.5 25.3861 19.2924 24.8715 18.8771 24.4563C18.4618 24.041 17.9472 23.8333 17.3333 23.8333C16.7194 23.8333 16.2049 24.041 15.7896 24.4563C15.3743 24.8715 15.1667 25.3861 15.1667 26C15.1667 26.6139 15.3743 27.1285 15.7896 27.5438C16.2049 27.959 16.7194 28.1667 17.3333 28.1667ZM17.3333 19.5C17.9472 19.5 18.4618 19.2924 18.8771 18.8771C19.2924 18.4618 19.5 17.9472 19.5 17.3333C19.5 16.7194 19.2924 16.2049 18.8771 15.7896C18.4618 15.3743 17.9472 15.1667 17.3333 15.1667C16.7194 15.1667 16.2049 15.3743 15.7896 15.7896C15.3743 16.2049 15.1667 16.7194 15.1667 17.3333C15.1667 17.9472 15.3743 18.4618 15.7896 18.8771C16.2049 19.2924 16.7194 19.5 17.3333 19.5Z" fill="#FFC727" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Organisations section html*/}
            {/* hiring box html */}
            <div className='employee-skill-wrapper lg:px-0 px-5 pt-10 '>
                <div className='relative'>
                    <div className='top-image-1 lg:block hidden w-full absolute lg:-top-[20%] xl:-top-0 left-0 z-[-1]'>
                        <img src={top2} className='w-full h-auto' alt="" />
                    </div>
                    <div className='max-w-3xl mx-auto flex flex-col gap-[18px] justify-center items-center lg:pt-[104px]'>
                        <h3 className='text-[#191919] font-semibold capitalize ff-pop md:text-3xl text-[28px] lg:text-[46px] !leading-[1.3] text-center my-0'>One Stop Solution to our new age Employee Lifecycle</h3>
                    </div>
                    <div className='lg:mt-36 lg:pb-32 mt-16 pb-16'>
                        <div className='flex flex-col items-center justify-center gap-10'>
                            <div className='flex lg:flex-row flex-col items-center lg:items-start  justify-center lg:gap-4 gap-10'>

                                <div className='lg:w-[500px] lg:order-1 order-1'>
                                    <h3 className='font-semibold text-base  lg:text-[26px] lg:text-right ff-pop  leading-normal mt-0 mb-3'>Stage 1 - Hiring the Best Talent </h3>
                                    <p className='lg:text-right ff-inter text-[#E73C3C] text-base lg:font-lg leading-normal font-bold'>Challenge:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>How can we attract and identify the right talent in a competitive market while reducing time-to-hire?</p>
                                    <p className='lg:text-right ff-inter text-[#13A758] text-base lg:font-lg  leading-normal font-bold mt-8'>How Third Bracket Helps:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>Our AI-powered recruitment tools streamline sourcing, screening, and shortlisting candidates, leveraging data insights to ensure you select top talent efficiently and effectively.</p>
                                </div>
                                <div className='w-28 lg:block hidden lg:order-1 order-2'>
                                    <img src={middlesperate} width={120} className='w-full h-auto' alt="" />
                                </div>
                                <div className='lg:w-[500px] lg:order-3 order-1 lg:flex w-full justify-start'>
                                    <div className='w-32'>
                                        <img src={Sign} width={150} height={125} className='w-full h-auto' alt="" />
                                    </div>
                                </div>

                            </div>
                            <div className='flex lg:flex-row flex-col items-center lg:items-start  justify-center lg:gap-4 gap-10'>
                                <div className='lg:w-[500px] flex lg:items-end lg:justify-end lg:order-1 order-2'>
                                    <div className='lg:w-64 w-40'>
                                        <img src={character} width={255} className='w-full h-auto' alt="" />
                                    </div>
                                </div>
                                <div className='lg:w-28 w-10 lg:order-2 order-1'>
                                    <img src={middlesperate} width={120} className='w-full h-auto' alt="" />
                                </div>

                                <div className='lg:w-[500px] lg:order-3 order-3'>
                                    <h3 className='font-semibold text-base  lg:text-[26px] text-left ff-pop  leading-normal mt-0 mb-3'>Stage 2 - Cultural Fitment </h3>
                                    <p className='text-left ff-inter text-[#E73C3C] text-base lg:font-lg leading-normal font-bold'>Challenge:</p>
                                    <p className='text-left ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>How do we ensure new hires align with our organizational values and integrate seamlessly into our culture?</p>
                                    <p className='text-left ff-inter text-[#13A758] text-base lg:font-lg  leading-normal font-bold mt-8'>How Third Bracket Helps:</p>
                                    <p className='text-left ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'> e provide detailed cultural alignment assessments, using psychometric and behavioral analysis to match candidates with your organizational ethos, reducing turnover and improving team synergy.</p>
                                </div>


                            </div>
                            <div className='flex lg:flex-row flex-col items-center lg:items-start  justify-center lg:gap-4 gap-10'>

                                <div className='lg:w-[500px]  lg:order-1 order-3'>
                                    <h3 className='font-semibold text-base  lg:text-[26px] lg:text-right ff-pop  leading-normal mt-0 mb-3'>Stage 3 - Skill Profiling </h3>
                                    <p className='lg:text-right ff-inter text-[#E73C3C] text-base lg:font-lg leading-normal font-bold'>Challenge:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>How can we gain an accurate understanding of our employees & skills and identify gaps in our workforce?</p>
                                    <p className='lg:text-right ff-inter text-[#13A758] text-base lg:font-lg  leading-normal font-bold mt-8'>How Third Bracket Helps:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>
                                        How Third Bracket Helps:Our platform uses advanced skill profiling tools to map employees’ strengths and areas for improvement, delivering actionable insights to optimise workforce planning and training strategies.</p>
                                </div>
                                <div className='lg:w-28 w-10  lg:order-2 order-1'>
                                    <img src={middlesperate} width={120} className='w-full h-auto' alt="" />
                                </div>
                                <div className='lg:w-[500px] flex flex-col items-center h-full justify-center lg:order-3 order-2'>
                                    <div className='lg:w-[400px] w-full mx-auto'>
                                        <img src={Screen} width={400} className='w-full h-auto' alt="" />
                                    </div>
                                </div>

                            </div>
                            <div className='flex lg:flex-row flex-col items-center lg:items-start  justify-center lg:gap-4 gap-10'>
                                <div className='lg:w-[500px] flex lg:items-end lg:justify-end lg:order-1 order-2'>
                                    <div className='lg:w-56 w-40'>
                                        <img src={baggg} width={220} className='w-full h-auto' alt="" />
                                    </div>
                                </div>
                                <div className='lg:w-28 w-10 lg:order-2 order-1'>
                                    <img src={middlesperate} width={120} className='w-full h-auto' alt="" />
                                </div>
                                <div className='lg:w-[500px] lg:order-3 order-3'>
                                    <h3 className='font-semibold text-base  lg:text-[26px] text-left ff-pop  leading-normal mt-0 mb-3'>Stage 4 - Upskilling</h3>
                                    <p className='text-left ff-inter text-[#E73C3C] text-base lg:font-lg leading-normal font-bold'>Challenge:</p>
                                    <p className='text-left ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>How do we ensure employees remain adaptable and skilled in an ever-changing business landscape?</p>
                                    <p className='text-left ff-inter text-[#13A758] text-base lg:font-lg  leading-normal font-bold mt-8'>How Third Bracket Helps:</p>
                                    <p className='text-left ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>We offer personalised learning paths and integrated upskilling programs tailored to individual and organisational needs, enabling your workforce to stay ahead of industry trends.</p>
                                </div>
                            </div>
                            <div className='flex lg:flex-row flex-col items-center lg:items-start  justify-center lg:gap-4 gap-10'>

                                <div className='lg:w-[500px]  lg:order-1 order-3'>
                                    <h3 className='font-semibold text-base  lg:text-[26px] lg:text-right ff-pop  leading-normal mt-0 mb-3'>Stage 5 - Career Development</h3>
                                    <p className='lg:text-right ff-inter text-[#E73C3C] text-base lg:font-lg leading-normal font-bold'>Challenge:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>How can we support employees in visualizing and achieving their career goals while aligning them with business objectives?</p>
                                    <p className='lg:text-right ff-inter text-[#13A758] text-base lg:font-lg  leading-normal font-bold mt-8'>How Third Bracket Helps:</p>
                                    <p className='lg:text-right ff-inter text-[#626262] text-base lg:font-lg  leading-normal font-normal'>
                                        Our career mapping solutions create clear, data-backed growth trajectories, helping employees see their potential within your organisation and fostering long-term engagement and retention.</p>
                                </div>
                                <div className='lg:w-[60px] lg:block hidden lg:order-2 order-1'>
                                    <img src={yellowbol} width={60} className='w-full h-auto' alt="" />
                                </div>
                                <div className='lg:w-28 w-10 lg:hidden  lg:order-2 order-1'>
                                    <img src={middlesperate} width={120} className='w-full h-auto' alt="" />
                                </div>
                                <div className='lg:w-[500px] lg:order-3 order-2  lg:flex w-full justify-start'>
                                    <div className='lg:w-[200px] lg:h-80 w-[140px] h-80 myclass lg:mx-auto '>
                                        <img src={character2} width={200} className='w-full h-full' alt="" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='top-image-1 lg:block hidden w-full absolute bottom-0 left-0 z-[-1]'>
                        <img src={top3} className='w-full h-auto' alt="" />
                    </div>
                </div>
            </div>
            {/* hiring box html */}

            <div className='why-choose-wrapper overflow-hidden'>
                <div className='bg-[#FFC727] lg:py-28 py-16 relative'>
                    <div className='mx-auto max-w-7xl z-10 relative'>
                        <h3 className='text-[#191919] font-semibold px-5 lg:px-0 text-center ff-pop md:text-3xl text-[28px] lg:text-[46px] !leading-[1.3] lg:text-start my-0'>Why choose Third Bracket?</h3>
                        <div className='mt-16 w-full flex flex-col justify-center items-center gap-10 lg:gap-14'>
                            <div className='flex flex-col lg:flex-row items-center justify-between w-full order-2 lg:order-1 gap-10'>
                                <div className='flex-1'>
                                    <div>
                                        <h3 className='my-0 text-bold text-3xl md:text-4xl pl-4 md:pl-7 ff-pop text-[#191919]'>01</h3>
                                        <div className="bg-[#666666] h-[2px] w-80 md:w-[333px] mt-2.5 mb-7 relative after:absolute after:content-[''] after:-right-[68px] after:top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:left-0 before:bg-[#666] after:w-20 after:rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden "></div>
                                        <div className='pl-4 md:pl-7 w-full lg:w-[370px]'>
                                            <p className='text-black text-base lg:text-lg font-bold my-0 ff-pop'>Personalised Experiences:</p>
                                            <p className='my-0 text-[#626262] text-normal text-base lg:text-lg ff-pop'>Delivering customised development plans and opportunities tailored to individual needs.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-1 flex justify-end'>
                                    <div>
                                        <h3 className='my-0 text-bold text-3xl md:text-4xl pl-4 md:pr-7 lg:text-right ff-pop text-[#191919]'>02</h3>
                                        <div className="bg-[#666666] h-[2px] w-80 md:w-[333px] md:-right-7 mt-2.5 mb-7 relative after:absolute after:content-[''] after:-left-[68px] after:top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:right-0 before:bg-[#666] after:w-20 after:-rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden"></div>
                                        <div className='pl-4 md:pl-0 md:pr-7 w-full lg:w-[370px]'>
                                            <p className='text-black lg:text-lg font-bold text-base lg:text-right my-0 ff-pop'>Continuous Feedback:</p>
                                            <p className='my-0 text-[#626262] text-normal lg:text-right text-base lg:text-lg ff-pop'>
                                                Providing real-time analytics and insights for informed decision-making and proactive engagement.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row items-center justify-centerw-full md:w-[600px] order-1 lg:order-2 lg:px-0 px-5'>
                                <img src={yelobg} className='w-full h-auto' alt="yelobg" />
                            </div>
                            <div className='flex flex-col lg:flex-row items-center justify-between w-full order-3 lg:order-3 gap-10'>
                                <div className='flex-1'>
                                    <div>
                                        <h3 className='my-0 text-bold text-3xl md:text-4xl pl-4 md:pl-7 ff-pop text-[#191919]  lg:hidden'>03</h3>
                                        <div className="bg-[#666666] h-[2px] lg:hidden  w-80 md:w-[333px] mt-2.5 mb-7 relative after:absolute after:content-[''] after:-right-[68px] after:-top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:left-0 before:bg-[#666] after:w-20 after:-rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden"></div>

                                        <div className='pl-4 md:pl-7 w-full lg:w-[370px]'>
                                            <p className='text-black text-base lg:text-lg font-bold my-0 ff-pop'>Seamless Interactions:</p>
                                            <p className='my-0 text-[#626262] text-normal text-base lg:text-lg ff-pop'>
                                                Creating a unified experience across all stages of the employee life cycle.</p>
                                        </div>
                                        <div className="bg-[#666666] h-[2px] hidden lg:block  w-80 md:w-[333px] mb-2.5 mt-7 relative after:absolute after:content-[''] after:-right-[68px] after:-top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:left-0 before:bg-[#666] after:w-20 after:-rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden"></div>
                                        <h3 className='my-0 text-bold text-3xl md:text-4xl pl-4 md:pl-7 ff-pop text-[#191919] hidden lg:block'>03</h3>
                                    </div>
                                </div>
                                <div className='flex-1 flex justify-end'>
                                    <div> <h3 className='my-0 text-bold text-3xl md:text-4xl pl-4 md:pr-7 lg:text-right ff-pop text-[#191919]  lg:hidden'>04</h3>

                                        <div className="bg-[#666666] h-[2px]  lg:hidden w-80 md:w-[333px] mt-2.5 mb-7 md:-right-7 relative after:absolute after:content-[''] after:-left-[68px] after:-top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:right-0 before:bg-[#666] after:w-20 after:rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden"></div>

                                        <div className='pl-4 md:pl-0 md:pr-7 w-full lg:w-[370px]'>
                                            <p className='text-black lg:text-lg font-bold text-base lg:text-right my-0 ff-pop'>Growth Opportunities:</p>
                                            <p className='my-0 text-[#626262] text-normal lg:text-right text-base lg:text-lg ff-pop'>
                                                Offering clear career progression and skill-building initiatives to keep employees motivated.</p>
                                        </div>

                                        <div className="bg-[#666666] h-[2px] hidden lg:block w-80 md:w-[333px] mb-2.5 mt-7 md:-right-7 relative after:absolute after:content-[''] after:-left-[68px] after:-top-[28px] before:content-[''] before:absolute before:w-2 before:h-2 before:top-[-3px] before:rounded-full before:right-0 before:bg-[#666] after:w-20 after:rotate-45 after:h-[2px] after:bg-[#666666] md:after:block after:hidden md:before:block before:hidden"></div>
                                        <h3 className='my-0 text-bold text-3xl md:text-4xl pr-4 md:pr-7 text-right ff-pop text-[#191919] hidden lg:block'>04</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='top-image-1 lg:block hidden w-full absolute bottom-[-10%] left-0 z-[1]'>
                        <img src={top4} className='w-full h-auto' alt="" />
                    </div> */}
                </div>
            </div>

            <div className='bg-white '>
                <div className="mx-auto max-w-7xl px-5 lg:px-0">
                    <div className='lg:pt-[102px] pt-14'>
                        <div className='lg:mb-[52px] mb-9'>
                            <h3 className='text-[#191919] ff-pop font-semibold text-[28px] lg:text-[46px] leading-tight  my-0'>Industry Case Studies</h3>
                            <p className='ff-pop font-normal lg:text-[24px] leading-tight text-lg text-[#191919] my-0 lg:max-w-[50%]'> Get a sneak peek into proven industry records</p>
                        </div>
                    </div>
                    <div className='border-[#ffc727]  shadow-[0px_0px_31.86px_0px_#ffe7a5] rounded-md border relative'>
                        <div className='flex  flex-col lg:flex-row lg:items-center justify-start gap-10 p-5 rounded-md border  border-[#061927]'>
                            <div className='gap-2 flex-col flex items-start justify-start'>
                                <p className='text-[#626262] text-lg lg:text-2xl font-normal ff-inter'>
                                    Through Third Bracket's advanced analytics and tailored assessments, we partnered with a fintech unicorn and  identified key skill gaps, personalized development plans, and created a tailored learning  pathway for upskilling. This initiative aimed to evaluate and map critical skills across individual contributors and leadership roles, ensuring alignment with organizational objectives and growth strategies.</p>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            {/* industry html */}
            {/* faq html */}
            <div className='bg-white'>
                <div className="mx-auto max-w-7xl px-4 lg:px-0">
                    <div className='lg:pt-[102px] pt-10'>
                        <div className='lg:mb-[52px] mb-9'>
                            <h3 className='text-[#191919] ff-pop font-semibold text-[28px] lg:text-[46px]  my-0'>Frequently Asked Questions</h3>
                            <p className='ff-pop font-normal lg:text-[24px] text-lg text-[#191919] my-0 lg:max-w-[50%]'>Explore our FAQ section to find answers about Third Bracket’s HR tech solutions</p>
                        </div>
                        <div className='flex flex-col gap-4 lg:pb-20  pb-10'>
                            <IntegrationAccordion />
                        </div>

                    </div>
                </div>
            </div>
            {/* faq html */}
            {/* footer html */}
            <Footer2 />
            {/* footer hmtl */}
        </>
    )
}

export default Home