import React from 'react'
import Header2 from '../../components/shared/Header2'
import Footer2 from '../../components/shared/Footer2'
import speechbubble from '../../assets/about/speech-bubble.png';
import foldright from '../../assets/about/fold-right.png';
import foldleft from '../../assets/about/fold-left.png';
import top3 from '../../assets/new-image/new-bg.png';
import booksRight from '../../assets/about/books-right.png';
import pageLeft from '../../assets/about/page-left.png';
import earth from '../../assets/about/earth.png';

function SolutionNew() {

    const [whyusetb, setwhyusetb] = React.useState([
        {
            name: "End-to-End Talent Solutions ",
            text: "From recruitment to retention, manage every stage of the employee lifecycle on a single platform."
        },
        {
            name: "Cultural Fitment Analysis",
            text: "Identify candidates who align with your organisational values for better collaboration and long-term success."
        },
        {
            name: "Upskilling & Reskilling",
            text: "Equip your workforce with skills that matter, ensuring adaptability in dynamic market conditions."
        },
        {
            name: "Skill Profiling ",
            text: "Leverage data-driven insights to identify employee potential and map career growth pathways."
        },
        {
            name: "Seamless Integration",
            text: "Third Bracket works effortlessly with your existing systems, providing a unified and user-friendly experience."
        }
    ]);

    return (
        <div>
            <Header2 />

            {/* about banner start*/}
            <section className="bg-banner-home relative " >
                <div className='max-w-7xl lg:px-0 px-4 mx-auto'>
                    <div className="flex lg:flex-row flex-col items-center justify-between gap-8 lg:py-32 pt-10">
                        <div className='flex-1'>
                            <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                                Assessment As A Solution
                            </h3>
                            <p className='font-normal lg:text-lg text-center text-base  text-[#40566D] lg:max-w-[900px] mt-9 mx-auto ff-inter'>
                                Welcome to Third Bracket, where we empower enterprise organizations to unlock the full potential of their workforce. Our cutting-edge assessment solutionsare designed to help you make data-driven decisions that align with your talent strategy and business goals.
                            </p>
                            <h3 className='text-[#191919] text-lg lg:mt-24 mt-10 lg:text-[26px] font-bold ff-pop text-center'>Measure Employee Skill-Gaps and Get Them Role-Ready</h3>
                            <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-4 lg:mt-7 mx-auto ff-inter'>
                                In today’s competitive landscape, staying ahead means having the right people with the right skills. With Third Bracket, you can identify skill gaps across teams, ensuring every employee is equipped to meet current and future challenges. Our tailored assessments provide actionable insights that guide targeted learning and development interventions, getting your workforce role-ready efficiently and effectively.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='absolute top-16 lg:w-[124px] w-12 right-4  lg:right-24'>
                    <img src={speechbubble} alt="speechbubble-mark" width={124} height={124} />
                </div>

                <div className='top-image-1 lg:block hidden w-full absolute lg:-top-[10%] left-0 z-[-1]'>
                    <img src={top3} className='w-full h-auto' alt="" />
                </div>
            </section>
            {/* about banner end */}

            {/* Why Use Third Bracket start */}
            <div className='bg-[#FBFBFB] py-10 px-4 lg:px-0 relative'>
                <div>
                    <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center'>Assess High-Potential Employees and Invest in Their Growth</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-4 lg:mt-7 mx-auto ff-inter'>
                        Great organizations don’t just identify high-potential employees—they invest in them. Third Bracket’s solutions help you pinpoint top talent with precision, evaluate their readiness for leadership roles, and design personalized growth journeys. With us, you can nurture your rising stars and keep them engaged,
                        motivated, and prepared to drive organizational success.
                    </p>
                </div>
                <div className='lg:my-36 mt-10 mb-0'>
                    <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center'>Identify the Right Skill-Gap, Every Time</h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#626262] lg:max-w-[900px] mt-4 lg:mt-7 mx-auto ff-inter'>
                        Understanding where to focus your resources is critical. Third Bracket’s innovative tools help you uncover the exact skill gaps holding your organization back. Our deep analytics and comprehensive reports provide clarity, enabling you to implement targeted training programs that maximize ROI and improve
                        performance. At Third Bracket, we’re more than an assessment provider—we’re your strategic partner in building a future-ready workforce. Let’s transform potential into performance, together.
                    </p>
                </div>
                <div className='absolute left-0 bottom-0 lg:w-[500px] lg:block hidden'>
                    <img src={pageLeft} className='w-full h-auto' width={400} alt="" />
                </div>
                <div className='absolute right-0 bottom-0 lg:w-[300px] lg:block hidden'>
                    <img src={booksRight} className='w-full h-auto' width={386} alt="" />
                </div>
            </div>
            <div className='bg-[#FFC727]  relative overflow-hidden'>
                <div className='bg-no-repeat bg-contain bg-right lg:py-28 lg:px-0 px-4 py-10 bg-none-1' style={{ backgroundImage: `url(${earth})` }}>
                    <h3 className='font-semibold ff-pop lg:text-4xl xl:text-[46px] leading-[1.2] mb-[18px] text-[28px] text-[#191919] lg:max-w-full text-center'>
                        Platform as a Solution
                    </h3>
                    <p className='font-normal lg:text-lg text-center text-base  text-[#40566D] lg:max-w-[600px] mt-10 mx-auto ff-inter'>
                        At Third Bracket, we believe in creating smarter, seamless, and scalable solutions that empower your organization to achieve more. Our platform solutions
                        are designed to partner with enterprise organizations like yours, offering bespoke tools that meet your unique business needs.
                    </p>

                    <div className='lg:ml-52  lg:max-w-[550px]'>
                        <div className='lg:mt-28 mt-10'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center lg:text-left'>Tailored Platforms for Your Success</h3>
                            <p className='font-normal lg:text-lg text-center lg:text-left text-base  text-[#626262] lg:max-w-[900px] mt-5 ff-inter'>
                                From Learning Management Systems (LMS) that foster growth and development, to Performance Management tools that drive excellence, to Reward and Recognition platforms that celebrate success—our solutions are crafted to fit your goals perfectly.
                            </p>
                        </div>
                        <div className='lg:mt-24 mt-10'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center lg:text-left'>Effortless Integration with Existing Systems</h3>
                            <p className='font-normal lg:text-lg text-center lg:text-left text-base  text-[#626262] lg:max-w-[900px] mt-5 ff-inter'>
                                We understand that the strength of a system lies in its ability to work together. That’s why our platforms are built with integration in mind. Whether you’re enhancing your existing ecosystem or creating a new one, our tools connect seamlessly with your current platforms, ensuring a smooth and unified
                                experience.
                            </p>
                        </div>
                        <div className='lg:mt-24 mt-10'>
                            <h3 className='text-[#191919] text-lg  lg:text-[26px] font-bold ff-pop text-center lg:text-left mb-5'>Why Choose Third Bracket?</h3>
                            <ul className='ml-0 m-0 list-disc'>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Customizable Solutions:</b> Every organization is unique. We tailor our platforms to align with your strategy and culture.
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Scalable Design:</b> As your business grows, our solutions evolve with you.
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>User-Centric Experience:</b> Built for engagement and ease of use, our tools prioritize your teams.
                                    </p>
                                </li>
                                <li>
                                    <p className='font-normal lg:text-lg text-left text-base  text-[#626262] lg:max-w-[900px] mb-2  ff-inter'>
                                        <b className='font-bold ff-inter'>Expert Collaboration:</b> We work closely with you, ensuring every solution is aligned with your business objectives.
                                    </p>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className='w-[300px] 2xl:w-[300px] absolute -bottom-20 -right-16 lg:block hidden'>
                    <img src={foldright} width={326} alt="fold-right" className='w-full h-auto' />
                </ div>
                <div className='w-[326px] 2xl:w-[400px] absolute bottom-0 left-0 lg:block hidden'>
                    <img src={foldleft} width={500} alt="fold-right" className='w-full h-auto' />
                </div>
            </div>


            {/* About Us start */}

            <Footer2 />
        </div>
    )
}

export default SolutionNew