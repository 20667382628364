import React, { useState } from 'react';
import logo from '../../assets/new-image/logo2.png';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link } from 'react-router-dom';

const Header2 = () => {
    const [menu, setMenu] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    return (
        <header className="bg-[#061927] top-0 sticky z-50">
            <nav
                className="mx-auto flex py-4 max-w-7xl items-center justify-between lg:py-5 lg:px-0 px-4"
                aria-label="Global"
            >
                <div className="flex lg:flex-1">
                    <a href="/" className="w-[120px]">
                        <img className="w-full h-auto" src={logo} alt="" />
                    </a>
                </div>
                <div className='flex-1 flex justify-end items-center gap-24'>
                    <div className='hidden lg:flex flex-row items-center justify-end gap-[69px] '>
                        <Link
                            to="/our-story"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Our Story
                        </Link>
                        <Link
                            to="/products"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Products
                        </Link>
                        <Link
                            to="/solutions"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Solutions
                        </Link>
                        <Link
                            to="/"
                            className="text-base/8 font-normal text-white text-nowrap font-mulish "
                        >
                            Commercials
                        </Link>

                    </div>
                    <div className='flex items-center justify-end gap-6'>
                        <div className='dropdown-links relative'>
                            <div
                                className="focus:outline-none  bg-[#FFC727] hover:bg-white font-medium rounded-sm text-base lg:text-lg  px-5 py-2   flex items-center justify-center gap-1 font-mulish  cursor-pointer"
                            >
                                <span className='text-[#061927] flex font-mulish'>
                                    Login
                                </span>
                                <span className='flex items-center justify-center'>
                                    <svg width="12" height="12" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="#061927" />
                                    </svg>
                                </span>
                            </div>
                            <div className='dropdown-subitems shadow-lg flex-col items-start gap-2 '>
                                <Link
                                    to={'/login'}
                                    className="focus:outline-none  bg-white hover:bg-[#ffc5276b] font-medium rounded-sm text-base lg:text-lg  px-5 py-2   flex items-center justify-center gap-1 font-mulish w-full"
                                >
                                    <span className='text-[#061927] flex font-mulish text-nowrap'>
                                        User Login
                                    </span>
                                </Link>
                                <Link
                                    to={'https://admin.thirdbracket.in/'}
                                    className="focus:outline-none  bg-white hover:bg-[#ffc5276b] font-medium rounded-sm text-base lg:text-lg  px-5 py-2   flex items-center justify-center gap-1 font-mulish w-full"
                                >
                                    <span className='text-[#061927] flex font-mulish text-nowrap'>
                                        Admin Login
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <button onClick={toggleDrawer} className='lg:hidden'>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z" fill="#fff" />
                            </svg>
                        </button>
                    </div>
                </div>


            </nav>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className=''
            >
                <div className='flex lg:hidden flex-col items-start justify-start gap-10 mt-10 px-10'>
                    <Link
                        to="/our-story"
                        className="text-lg font-normal text-[#061927] text-nowrap font-mulish "
                    >
                        Our Story
                    </Link>
                    <Link
                        to="/products"
                        className="text-lg font-normal text-[#061927] text-nowrap font-mulish "
                    >
                        Products
                    </Link>
                    <Link
                        to="/solutions"
                        className="text-lg font-normal text-[#061927] text-nowrap font-mulish "
                    >
                        Solutions
                    </Link>
                    <Link
                        to="/"
                        className="text-lg font-normal text-[#061927] text-nowrap font-mulish "
                    >
                        Commercials
                    </Link>

                </div>
            </Drawer>
        </header>
    );
};

export default Header2;
